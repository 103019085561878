const HistoryData = {
  1980: {
    initialYear: 1988,
    year: 1980,
    shortYear: 80,
    initialPercent: 80,
    timeLineArr: [1988, 1989],
    timeLine: {
      1988: {
        year: 1988,
        percent: 80,
        history: [
          {
            date: "9.18",
            content: [
              "김경화, 김용준, 정명환, 허원태 장로외 57명의 성인성도와 35명의 주일학교 학생이 첫 예배를 드림 (장소: Holy Trinity Anglican Church, 10087-87 Ave)",
            ],
            background: "grey",
          },
          {
            date: "9.18",
            content: [
              "교인 총회에서 본 교회는 미주한인 장로회 카나다 노회에 가입할 것과 교회 명칭을 '에드몬톤 한인 중앙 장로교회(가칭)'로 할 것을 결의",
            ],
            background: "white",
          },
          {
            date: "9.25",
            content: [
              "미주 한인 장로회 카나다 노회는 본 교회의 노회 가입을 승인하고 임시 당회장에 김재광 목사(토론토 영락교회 시무)를 임명",
            ],
            background: "grey",
          },
          {
            date: "10.7-9",
            content: ["교회 창립기념 특별집회 (강사: 김재광 목사)"],
            background: "white",
          },
          {
            date: "10.9",
            content: [
              "공동의회로 모이고 김명준 목사를 본 교회 위임목사로 청빙할것과, 본 교회 명칭을 '미주한인 장로회 에드몬톤 한인 중앙 장로교회' (The Edmonton Central Korean Presbyterian Church, The Presbyterian Church in America)로 하기로 결의",
            ],
            background: "grey",
          },
          {
            date: "10.23",
            content: [
              "본 교회 예배 장소를 9915-148 Street에 위치한 St Andrews United Church로 옮기다",
            ],
            background: "white",
          },
        ],
      },
      1989: {
        year: 1989,
        percent: 90,
        history: [
          {
            date: "1.6",
            content: ["김명준 목사 부임"],
            background: "grey",
          },
          {
            date: "1.29",
            content: ["한경호 교육전도사 부임"],
            background: "white",
          },
          {
            date: "3.22-26",
            content: [
              "제1회 청소년 수양회: 본교회와 캠프 쿠리아코스 (강사:본 교회 - 한경호 전도사, 미국 - 미셸 원, 아브라함 이, 조나단 유, 쟈넷 이)",
            ],
            background: "grey",
          },
          {
            date: "4.7-9",
            content: [
              "제1회 부흥사경회 (강사: 이성헌 목사/ 대한 예수교 장로회 총회장)",
            ],
            background: "white",
          },
          {
            date: "4.8",
            content: ["김명준 목사 위임식 (주관: 미주한인 장로회 카나다 노회)"],
            background: "grey",
          },
          {
            date: "10.5-8",
            content: ["제2회 교회창립 1주년 심령 대부흥회 (강사: 이경천 목사)"],
            background: "white",
          },
        ],
      },
    },
  },
  1990: {
    initialYear: 1990,
    year: 1990,
    shortYear: 90,
    initialPercent: 0,
    timeLineArr: [1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    timeLine: {
      1990: {
        year: 1990,
        percent: 0,
        history: [
          {
            date: "1.1",
            content: [
              "선교회와 구역명칭을 변경",
              "선교회: 에녹, 베드로, 바울, 디모데",
              "구역: 예루살렘, 사마리아, 빌립보, 에베소, 고린도, 안디옥",
            ],
            background: "grey",
          },
          {
            date: "1.23",
            content: ["어와나 (Awana 공인넘버-649) 시작"],
            background: "white",
          },
          {
            date: "4.1",
            content: ["한경호 전도사 사임"],
            background: "grey",
          },
          {
            date: "6.29 - 7.1",
            content: ["제2회 성가대 세미나 (강사: 박재훈 박사)"],
            background: "white",
          },
          {
            date: "9.12 - 15",
            content: [
              "제3회 창립 2주년 기념예배 및 부흥성회 (강사: 이준만 목사)",
            ],
            background: "grey",
          },
          {
            date: "10.1",
            content: ["이종영 교육 전도사 부임"],
            background: "white",
          },
        ],
      },
      1991: {
        year: 1991,
        percent: 10,
        history: [
          {
            date: "1.20",
            content: ["선교소식 창간호 발행"],
            background: "grey",
          },
          {
            date: "2.28",
            content: ["이종영 전도사 사임"],
            background: "white",
          },
          {
            date: "7.15 - 31",
            content: [
              "단기선교사 파송 - 인도와 스리랑카, 네팔",
              "인솔자: 김애문 사모",
              "파송 받은이: 박선아, 박건아, 이웅조, 이경조, 권석심, 김수진, 윤마이크 (중앙교회, 남 침례교회, 미주교회)",
            ],
            background: "grey",
          },
          {
            date: "10.13",
            content: ["문영명 교육전도사 부임"],
            background: "white",
          },
          {
            date: "10.16 - 20",
            content: ["제4회 부흥 사경회 (강사 : 이성헌 목사)"],
            background: "grey",
          },
          {
            date: "10.19",
            content: [
              "창립 3주년 기념예배 및 임직식",
              "장로장립: 이종수 조성업",
              "안수집사 임직: 김방휘 배신근 이종열",
              "권사임직: 안계선, 오성은",
            ],
            background: "white",
          },
        ],
      },
      1992: {
        year: 1992,
        percent: 20,
        history: [
          {
            date: "1.15",
            content: ["이웅조 교육전도사 부임"],
            background: "grey",
          },
          {
            date: "1.19",
            content: ["복음가수 초청 찬양의 밤 (안철호전도사)"],
            background: "white",
          },
          {
            date: "4.27",
            content: ["제1기 전도폭발"],
            background: "grey",
          },
          {
            date: "5.26",
            content: ["알버타 주정부에 본 교회 등록 (#54527290)"],
            background: "white",
          },
          {
            date: "6.21",
            content: [
              "김용준 장로, 이종열 집사 단기 선교사 파송 예배 (중국, 몽골)",
            ],
            background: "grey",
          },
          {
            date: "8.1",
            content: ["교회 건물을 11525-23Ave에 소재한 N.A.B.C로 이전"],
            background: "white",
          },
          {
            date: "8.3 - 28",
            content: [
              "청소년들 멕시코 선교여행 (문영명, 이웅조 전도사외 38명)",
            ],
            background: "grey",
          },
        ],
      },
      1993: {
        year: 1993,
        percent: 30,
        history: [
          {
            date: "1.17",
            content: [
              "교회 창립 5주년 기념사업 준비위원회 구성",
              "위원장: 정명환 장로, 부위원장: 오성은 권사",
              "위원: 제직원 각부부장, 선교회 회장",
            ],
            background: "grey",
          },
          {
            date: "2.14",
            content: [
              "성전건축 준비위원회 구성",
              "위원장: 김경화 장로",
              "임원: 총무(서기겸직) - 이종수 장로, 회계(재무) - 김방휘 집사",
              "위원: 당회원, 각선교회장, 대학부 대표",
              "회원: 전성도",
            ],
            background: "white",
          },
          {
            date: "3.7",
            content: ["복음성가 가수 손영진 초청 찬양 간증의 밤"],
            background: "grey",
          },
          {
            date: "6.1",
            content: ["고 김경화 장로 별세 (교회장)"],
            background: "white",
          },
          {
            date: "6.6",
            content: [
              "전도폭발 제1기 수료식 (수료자: 이태기 집사, 김성규 집사, 표연순 집사)",
            ],
            background: "grey",
          },
          {
            date: "6.6",
            content: [
              "단기선교사 파송예배 한국 여행팀 16명 (인솔: 이웅조 전도사, 캐롤 교사/ N.A.B.C 교수)",
            ],
            background: "white",
          },
          {
            date: "9.18",
            content: [
              "창립 5주년 기념행사 및 임직식",
              "안수집사 임직: 이태기",
              "권사임직: 신창애, 홍영숙",
            ],
            background: "grey",
          },
          {
            date: "9.17 - 19",
            content: ["창립 5주년 기념 부흥회 (강사: 문오장 목사)"],
            background: "white",
          },
          {
            date: "12.12",
            content: ["디아스포라 창간"],
            background: "grey",
          },
        ],
      },
      1994: {
        year: 1994,
        percent: 40,
        history: [
          {
            date: "1.1",
            content: ["성경 100독 대행진 시작"],
            background: "grey",
          },
          {
            date: "2.26",
            content: ["다윗 아이삭 전도사 부임"],
            background: "white",
          },
          {
            date: "4.29-5.1",
            content: [
              "전교인 수양회 (장소: Camp Nakamun, 강사: 오대원 목사)",
              "학생 수양회 (강사: 신태영 목사)",
            ],
            background: "grey",
          },
          {
            date: "5.8",
            content: ["이웅조 전도사 사임"],
            background: "white",
          },
          {
            date: "5.17",
            content: ["전도폭발 2기 개강"],
            background: "grey",
          },
          {
            date: "10.13-16",
            content: ["영적 각성 대집회 (강사: 이상남 목사)"],
            background: "white",
          },
          {
            date: "10.15",
            content: ["디아스포라 2호 발간"],
            background: "grey",
          },
          {
            date: "12.25",
            content: ["김명준 목사 사임"],
            background: "white",
          },
        ],
      },
      1995: {
        year: 1995,
        percent: 50,
        history: [
          {
            date: "6.15",
            content: ["김수광 목사 부임"],
            background: "grey",
          },
          {
            date: "6.23-25",
            content: ["심령부흥성회 (강사: 박승은 목사/ 동승교회 원로 목사)"],
            background: "white",
          },
          {
            date: "7.30",
            content: ["부산 수영로교회 단기선교단 내방 찬양집회"],
            background: "grey",
          },
          {
            date: "9.16",
            content: [
              "창립 7주년 기념예배 및 김수광 목사 취임식",
              "추석맞이 민속잔치",
              "디아스포라 3호 발간",
            ],
            background: "white",
          },
          {
            date: "10.29",
            content: ["허원태 장로 은퇴"],
            background: "grey",
          },
        ],
      },
      1996: {
        year: 1996,
        percent: 60,
        history: [
          {
            date: "6.2",
            content: ["김수광 목사 사임"],
            background: "white",
          },
          {
            date: "7.21",
            content: ["강재현 목사 부임"],
            background: "grey",
          },
          {
            date: "11.30",
            content: [
              "임직식",
              "안수집사 임직: 김명수, 정용환, 이종원",
              "안수집사 취임: 김혜양",
              "권사임직: 표연순",
            ],
            background: "white",
          },
        ],
      },
      1997: {
        year: 1997,
        percent: 70,
        history: [
          {
            date: "5.30",
            content: ["강재현 목사 사임"],
            background: "grey",
          },
          {
            date: "6.1",
            content: ["강성철 목사 부임"],
            background: "white",
          },
          {
            date: "9.7",
            content: ["소그룹 사랑방 모임 시작"],
            background: "grey",
          },
          {
            date: "11.16",
            content: ["강성철 목사 취임예배"],
            background: "white",
          },
          {
            date: "12.21",
            content: ["김용준, 정명환 장로를 시무 장로로"],
            background: "grey",
          },
        ],
      },
      1998: {
        year: 1998,
        percent: 80,
        history: [
          {
            date: "1.26-28",
            content: ["부흥회 (강사: 고 훈 목사)"],
            background: "white",
          },
          {
            date: "2.8",
            content: ["다윗 전도사 사임"],
            background: "grey",
          },
          {
            date: "4.5",
            content: ["양신열 교육전도사 부임"],
            background: "white",
          },
          {
            date: "9.25-26",
            content: ["10주년 부흥집회 (강사: 김상환 목사)"],
            background: "grey",
          },
          {
            date: "9.27",
            content: ["홍영숙 권사 은퇴, 손동분, 김영례 명예권사 추대"],
            background: "white",
          },
          {
            date: "10.4",
            content: ["양신열 전도사 사임"],
            background: "grey",
          },
          {
            date: "11.15",
            content: ["이재현 교육전도사 부임"],
            background: "white",
          },
          {
            date: "11.22",
            content: ["정병호 목사 부임 (협동목사)"],
            background: "grey",
          },
        ],
      },
      1999: {
        year: 1999,
        percent: 90,
        history: [
          {
            date: "3.28",
            content: ["정병호 목사 사임"],
            background: "white",
          },
          {
            date: "7.27",
            content: ["임시 공동의회 (건축헌금)"],
            background: "grey",
          },
          {
            date: "10.1-3",
            content: ["11주년 기념 부흥집회 (강사: 유인영 선교사)"],
            background: "white",
          },
          {
            date: "10.3",
            content: ["심민보 교육전도사 부임 (아동부)"],
            background: "grey",
          },
        ],
      },
    },
  },
  2000: {
    initialYear: 2000,
    year: 2000,
    shortYear: 0,
    initialPercent: 0,
    timeLineArr: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009],
    timeLine: {
      2000: {
        year: 2000,
        percent: 0,
        history: [
          {
            date: "2.6",
            content: ["성경통신대학 개강"],
            background: "grey",
          },
          {
            date: "10.1",
            content: ["공동의회 - 강성철 목사 위임 투표 (인도: 김창선 목사)"],
            background: "white",
          },
        ],
      },
      2001: {
        year: 2001,
        percent: 10,
        history: [
          {
            date: "1.7",
            content: ["김재봉 목사 부임 (중고등부)"],
            background: "grey",
          },
          {
            date: "1.21",
            content: ["청년 대학부 예배 시작"],
            background: "white",
          },
          {
            date: "4.22",
            content: [
              "직원선거를 위한 공동의회",
              "장로피택: 김명수, 이태기, 정용환, 이종원",
              "집사피택: 나정신, 정성학, 이홍연, 이수철",
              "권사피택: 정회월, 최형복, 한효숙",
            ],
            background: "grey",
          },
          {
            date: "7.1",
            content: [
              "교회창립 13주년 기념행사 준비위원회 조직",
              "위원장: 정명환 장로",
              "위원: 피택자 전원, 김용준, 이종수, 이명종 장로",
            ],
            background: "white",
          },
          {
            date: "9.9",
            content: ["교사예배 시작"],
            background: "grey",
          },
          {
            date: "9.21-22",
            content: ["교회창립기념 부흥회 (이성오 목사)"],
            background: "white",
          },
          {
            date: "9.22",
            content: [
              "교회창립 기념일 행사 임직식",
              "목사위임: 강성철 목사",
              "장로장립: 김명수, 이태기, 정용환, 이종원",
              "장로은퇴: 김용준",
              "안수집사취임: 이홍연, 이수철",
              "안수집사: 정성학",
              "권사임직: 정회월, 최형복, 한효숙",
            ],
            background: "grey",
          },
          {
            date: "9.22",
            content: ["디아스포라 4호 발간"],
            background: "white",
          },
          {
            date: "9.23",
            content: ["금성교회와 자매결연"],
            background: "grey",
          },
          {
            date: "9.30",
            content: ["협동장로 추대 (이종수, 이명종 장로)"],
            background: "white",
          },
        ],
      },
      2002: {
        year: 2002,
        percent: 20,
        history: [
          {
            date: "1.13",
            content: [
              "건축위원회 조직",
              "위원장: 정용환장로",
              "위원: 김명수, 이태기, 이종원 장로, 정수환, 김수련, 노황률 집사",
            ],
            background: "grey",
          },
          {
            date: "8.1-10",
            content: ["금성교회 교환학생 방문 (인도: 이성오목사)"],
            background: "white",
          },
          {
            date: "9.22",
            content: ["디아스포라 5호 발간"],
            background: "grey",
          },
          {
            date: "10.6",
            content: ["유인영 선교사 선교보고"],
            background: "white",
          },
          {
            date: "12.29",
            content: ["심민보 전도사 사임"],
            background: "grey",
          },
        ],
      },
      2003: {
        year: 2003,
        percent: 30,
        history: [
          {
            date: "1.18",
            content: ["교회음악 세미나 (정영환 목사)"],
            background: "grey",
          },
          {
            date: "1.27",
            content: ["김재봉 목사 사임"],
            background: "white",
          },
          {
            date: "3.2",
            content: ["류문현 교육전도사 부임 (청년부)"],
            background: "grey",
          },
          {
            date: "5.5",
            content: ["대구지하철 참사 유족돕기 일일식당 (베드로선교회 주최)"],
            background: "white",
          },
          {
            date: "8.30-9.1",
            content: ["전교인 수양회 (장소: Camp Oselia)"],
            background: "grey",
          },
          {
            date: "9.27",
            content: ["창립 15주년 기념 음악회"],
            background: "white",
          },
          {
            date: "9.28",
            content: ["창립 15주년 기념 사진 전시회"],
            background: "grey",
          },
          {
            date: "11.2-23",
            content: ["교사대학"],
            background: "white",
          },
        ],
      },
      2004: {
        year: 2004,
        percent: 40,
        history: [
          {
            date: "2.8",
            content: ["제자양육 프로그램 믿음과정 시작"],
            background: "grey",
          },
          {
            date: "3.3",
            content: ["류문현 목사 안수"],
            background: "white",
          },
          {
            date: "3.7",
            content: [
              "은희출 목사 부임 (아동부)",
              "청년대학부 예배 시간 변경 (11시→1시)",
            ],
            background: "grey",
          },
          {
            date: "4.23-25",
            content: ["부흥집회 (강사: 최삼경 목사 - 빛과소금교회)"],
            background: "white",
          },
          {
            date: "4.25",
            content: [
              "직원선거를 위한 공동의회",
              "안수집사 피택: 김수련, 안성운",
            ],
            background: "grey",
          },
          {
            date: "5.14-16",
            content: ["청소년 수련회 (강사: 박수웅 장로)"],
            background: "white",
          },
          {
            date: "6.13",
            content: ["1기 믿음과정 수료(24명)"],
            background: "grey",
          },
          {
            date: "8.2-21",
            content: ["비전트립 (한국 금성교회 외)"],
            background: "white",
          },
          {
            date: "9.17-19",
            content: [
              "창립 16주년 기념 세미나 (강사: 김명준 목사 - 지구촌교회)",
            ],
            background: "grey",
          },
          {
            date: "9.19",
            content: ["임직식", "안수집사 임직: 김수련, 안성운"],
            background: "white",
          },
          {
            date: "12.26",
            content: ["2기 믿음과정 수료 (19명)"],
            background: "grey",
          },
        ],
      },
      2005: {
        year: 2005,
        percent: 50,
        history: [
          {
            date: "1.23",
            content: ["교회 밴 구입 (기아 세도나)"],
            background: "white",
          },
          {
            date: "2.16",
            content: [
              "요게벳중창단 방문 (서울 소망교회)",
              "북한어린이를 위한 자선 음악회",
            ],
            background: "grey",
          },
          {
            date: "5.25-6.5",
            content: ["브라질 단기선교 (류문현 목사 외 10명)"],
            background: "white",
          },
          {
            date: "6.3-9",
            content: ["당회원 연합 세미나 (서울금성교회)"],
            background: "grey",
          },
          {
            date: "7.24",
            content: ["강성철 목사 사임"],
            background: "white",
          },
          {
            date: "7.31",
            content: ["이재현 전도사 사임"],
            background: "grey",
          },
          {
            date: "12.4",
            content: ["정동호 목사 부임"],
            background: "white",
          },
        ],
      },
      2006: {
        year: 2006,
        percent: 60,
        history: [
          {
            date: "2.7",
            content: ["제1기 제자훈련 개강"],
            background: "grey",
          },
          {
            date: "3.26",
            content: ["류문현 목사 사임"],
            background: "white",
          },
          {
            date: "5.7",
            content: ["Bernard Cheung 전도사 부임"],
            background: "grey",
          },
          {
            date: "7.2",
            content: [
              "직원선거를 위한 공동의회",
              "집사피택: 박태규, 이정수, 김준회, 박달서",
              "권사피택: 장승아, 남효정, 한연자",
            ],
            background: "white",
          },
          {
            date: "8.13",
            content: ["이세연 목사 부임"],
            background: "grey",
          },
          {
            date: "8.27",
            content: ["원로장로 추대: 정명환"],
            background: "white",
          },
          {
            date: "9.23",
            content: [
              "창립 18주년 기념 임직식",
              "안수집사 임직: 이정수, 김준회, 박달서",
              "안수집사 집사취임: 박태규",
              "권사임직: 장승아, 남효정, 한연자",
            ],
            background: "grey",
          },
          {
            date: "12.2-3",
            content: ["선교축제 (강사: 이경환 선교사/ 몽골)"],
            background: "white",
          },
          {
            date: "12.25",
            content: ["Bernard Cheung 전도사 사임"],
            background: "grey",
          },
        ],
      },
      2007: {
        year: 2007,
        percent: 70,
        history: [
          {
            date: "1.14",
            content: ["EM 예배시작"],
            background: "grey",
          },
          {
            date: "1.28",
            content: ["전태식 전도사 (다윗과 요나단) 특송"],
            background: "white",
          },
          {
            date: "2.4",
            content: ["오후 찬양 예배 시작", "석찬권 전도사 부임"],
            background: "grey",
          },
          {
            date: "2.18",
            content: ["건축위원회 공개 모임"],
            background: "white",
          },
          {
            date: "4.15",
            content: ["중.고등부와 청년.대학부 예배 분리"],
            background: "grey",
          },
          {
            date: "6.29-7.1",
            content: ["전교인 수련회 (장소: Camp Oselia)"],
            background: "white",
          },
          {
            date: "8.10",
            content: ["금요 심야 기도회 시작"],
            background: "grey",
          },
          {
            date: "10.12-14",
            content: ["선교축제 (강사: 전하라 선교사/ I국)"],
            background: "white",
          },
          {
            date: "10.14",
            content: ["새생명축제 (강사: 전하라 선교사)"],
            background: "grey",
          },
        ],
      },
      2008: {
        year: 2008,
        percent: 80,
        history: [
          {
            date: "1.20, 27",
            content: ["교사 대학 (강사: Bryan Kim, 김대열 목사, 이승훈 목사)"],
            background: "white",
          },
          {
            date: "3.30-4.1",
            content: [
              "4개교회연합 (중앙,한인,소망,순복음) 중고등부 수련회 (장소: Camp Nakamun)",
            ],
            background: "grey",
          },
          {
            date: "5.23-25",
            content: ["선교축제 (강사: 김영락 선교사/ 멕시코)"],
            background: "white",
          },
          {
            date: "6.24-27",
            content: ["서부 캐나다 코스타 에드먼턴"],
            background: "grey",
          },
          {
            date: "7.6",
            content: ["전교인 야유회"],
            background: "white",
          },
          {
            date: "8.24-26",
            content: ["Youth.청년 연합수련회"],
            background: "grey",
          },
          {
            date: "9.17",
            content: ["소프라노 전예랑 간증집회"],
            background: "white",
          },
          {
            date: "9.19-20",
            content: [
              "교회창립20주년",
              "권사은퇴: 신창애",
              "명예권사추대: 전미자",
              "디아스포라 6호 발간 (창립20주년기념)",
            ],
            background: "grey",
          },
          {
            date: "9.21",
            content: [
              "새생명축제 (강사: 정 현 목사/ Liberty Baptist Seminary 교수)",
            ],
            background: "white",
          },
        ],
      },
      2009: {
        year: 2009,
        percent: 90,
        history: [
          {
            date: "1.4",
            content: ["젊은이 예배시작"],
            background: "grey",
          },
          {
            date: "2.3",
            content: [
              "총회명칭 및 헌법전면개정 (미주한인장로회-->해외한인장로회)",
            ],
            background: "white",
          },
          {
            date: "3.15",
            content: ["담임목사 위임투표 (15일)"],
            background: "grey",
          },
          {
            date: "3.22",
            content: [
              "직원선거를 위한 공동의회",
              "집사피택: 조충연, 노황률",
              "권사피택: 이효진, 이승해",
            ],
            background: "white",
          },
          {
            date: "3.29-4.1",
            content: ["에드먼턴 청소년 연합수련회 (장소: Camp Nakamun)"],
            background: "grey",
          },
          {
            date: "5.26",
            content: ["다락방별 찬양경연대회"],
            background: "white",
          },
          {
            date: "6.16-24",
            content: [
              "인도(전하라 선교사) 선교지 방문 (정동호 목사, 선교부장 김준회 집사)",
            ],
            background: "grey",
          },
          {
            date: "6.28",
            content: ["이세연 목사 사임"],
            background: "white",
          },
          {
            date: "7.5",
            content: [
              "안수집사 임직: 조충연",
              "박효진 목사 부임",
              "전교인 야유회",
            ],
            background: "grey",
          },
          {
            date: "8.20-22",
            content: ["아동부 VBS"],
            background: "white",
          },
          {
            date: "8.28-30",
            content: [
              "전교인 수련회 (장소: Camp Oselia, 강사: 김정진 목사/ 벤쿠버영락교회)",
            ],
            background: "grey",
          },
          {
            date: "9.15",
            content: ["석찬권 목사 안수 (카나다 서노회, 벤쿠버)"],
            background: "white",
          },
          {
            date: "9.18-20",
            content: ["선교축제 (강사: 최상헌 목사/ 한국 현리교회)"],
            background: "grey",
          },
          {
            date: "9.19",
            content: [
              "담임 목사위임식 및 임직식",
              "목사위임: 정동호",
              "장로은퇴: 김명수",
              "안수집사 임직: 노황률",
              "권사임직: 이효진, 이승해",
            ],
            background: "white",
          },
          {
            date: "10.6",
            content: ["성경통독모임 시작"],
            background: "grey",
          },
          {
            date: "11.15",
            content: ["새생명축제 (강사: 정동호 목사)"],
            background: "white",
          },
        ],
      },
    },
  },
  2010: {
    initialYear: 2010,
    year: 2010,
    shortYear: 10,
    initialPercent: 0,
    timeLineArr: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019],
    timeLine: {
      2010: {
        year: 2010,
        percent: 0,
        history: [
          {
            date: "4.10",
            content: ["찬양대 세미나 (강사:윤소현 사모)"],
            background: "grey",
          },
          {
            date: "5.10-19",
            content: ["인도 단기선교"],
            background: "white",
          },
          {
            date: "6.22-25",
            content: ["코스타집회 (장소:Ambrose University College, 캘거리)"],
            background: "grey",
          },
          {
            date: "6.25-26",
            content: ["에드먼턴 교민코스타 (장소:한인장로교회)"],
            background: "white",
          },
          {
            date: "7.4",
            content: ["전교인 야유회"],
            background: "grey",
          },
          {
            date: "7.5-13",
            content: ["Youth Summer Outreach (멕시코, 티와나)"],
            background: "white",
          },
          {
            date: "8.29-31",
            content: ["청년대학부 Retreat"],
            background: "grey",
          },
          {
            date: "10.7",
            content: ["부흥한국 미주서부투어 집회"],
            background: "white",
          },
          {
            date: "10.10",
            content: ["추수감사주일"],
            background: "grey",
          },
          {
            date: "10.23",
            content: ["새성전 입당감사예배"],
            background: "white",
          },
          {
            date: "10.22-24",
            content: ["새새명축제 (강사: 김기동 목사-고구마전도왕)"],
            background: "grey",
          },
          {
            date: "11.20",
            content: ["기획당회"],
            background: "white",
          },
          {
            date: "11.28",
            content: ["Youth연합집회(Christ Fest)"],
            background: "grey",
          },
          {
            date: "12.2-3",
            content: ["에드먼턴교역자협의회 연합집회 (강사: 이종용목사)"],
            background: "white",
          },
          {
            date: "12.19-21",
            content: ["Youth 겨울수련회"],
            background: "grey",
          },
        ],
      },
      2011: {
        year: 2011,
        percent: 10,
        history: [
          {
            date: "1.21-22",
            content: ["청년대학부 겨울수련회"],
            background: "white",
          },
          {
            date: "1.22",
            content: ["에드먼턴 교회연합 교사 신년 세미나"],
            background: "grey",
          },
          {
            date: "3.2",
            content: [
              "바울선교회 헌신예배, 강사: 최동수 목사(밴쿠버서부장로교회)",
            ],
            background: "white",
          },
          {
            date: "3.11",
            content: ["전반기 교사세미나"],
            background: "grey",
          },
          {
            date: "3.27-30",
            content: ["에드먼턴 Youth 연합수련회"],
            background: "white",
          },
          {
            date: "4.9, 16",
            content: ["에드먼턴 교회 연합 제직세미나"],
            background: "grey",
          },
          {
            date: "4.10",
            content: [
              "직원선거를 위한 공동의회 장로피택: 박태규 김수련 안수집사 피택: 남상렬 김재현 최경보 안영만 권사피택: 이경예 서동희 김미숙 남정원",
            ],
            background: "white",
          },
          {
            date: "4.24",
            content: ["부활절 연합 새벽예배"],
            background: "grey",
          },
          {
            date: "4.27",
            content: ["선교 위원회 헌신예배"],
            background: "white",
          },
          {
            date: "5.2-11",
            content: ["인도 단기선교"],
            background: "grey",
          },
          {
            date: "6.11",
            content: ["베드로.디모데 선교회 연합야유회"],
            background: "white",
          },
          {
            date: "6.15",
            content: ["베드로 선교회 헌신예배, 강사: 주석환 목사(소망교회)"],
            background: "grey",
          },
          {
            date: "6.25",
            content: ["KOSTA 교민집회"],
            background: "white",
          },
          {
            date: "6.26",
            content: ["재정 세미나"],
            background: "grey",
          },
          {
            date: "7.10",
            content: ["전교인 야유회"],
            background: "white",
          },
          {
            date: "7.6-18",
            content: ["청년부 원주민 선교"],
            background: "grey",
          },
          {
            date: "7.24-26",
            content: ["청년부 수련회"],
            background: "white",
          },
          {
            date: "8.12-13",
            content: ["Youth Camping Trip"],
            background: "grey",
          },
          {
            date: "8.21",
            content: ["바울선교회 야유회"],
            background: "white",
          },
          {
            date: "9.2-4",
            content: ["부흥회, 강사: 박신일 목사(밴쿠버 그레이스교회)"],
            background: "grey",
          },
          {
            date: "10.11-12",
            content: ["청년부흥집회, 강사: 김은열 목사(뉴욕충신교회)"],
            background: "white",
          },
          {
            date: "10.22",
            content: [
              "임직식 장로장립: 박태규 김수련 안수집사 임직: 남상렬 김재현 최경보 안영만 권사임직: 이경예 서동희 김미숙 남정원",
            ],
            background: "grey",
          },
          {
            date: "10.31",
            content: ["교회학교 할렐루야 Night"],
            background: "white",
          },
          {
            date: "11.20",
            content: ["새생명축제"],
            background: "grey",
          },
          {
            date: "11.26",
            content: ["Christ Fest 2011(에드먼턴 청소년 연합집회)"],
            background: "white",
          },
          {
            date: "12.10",
            content: ["에드먼턴교회연합 성탄축하의 밤"],
            background: "grey",
          },
          {
            date: "12.24",
            content: ["성탄전야행사"],
            background: "white",
          },
          {
            date: "12.27-30",
            content: ["Rochester 11"],
            background: "grey",
          },
        ],
      },
      2012: {
        year: 2012,
        percent: 20,
        history: [
          {
            date: "1.21",
            content: ["에드먼턴 교회연합 교사 신년 세미나"],
            background: "grey",
          },
          {
            date: "1.28",
            content: ["제직세미나"],
            background: "white",
          },
          {
            date: "3.25-28",
            content: ["에드먼턴 Youth 연합수련회"],
            background: "grey",
          },
          {
            date: "4.8",
            content: ["부활주일 연합 새벽예배"],
            background: "white",
          },
          {
            date: "4.27-28",
            content: ["선교부흥회"],
            background: "grey",
          },
          {
            date: "5.7-9",
            content: [
              "어메이징 그레이스 참여 (정동호 이태기 박태규 김수련 남상렬)",
            ],
            background: "white",
          },
          {
            date: "5.27",
            content: ["전교인 성경퀴즈대회"],
            background: "grey",
          },
          {
            date: "6.29-7.18",
            content: ["카자흐스탄, 우크라이나 단기선교"],
            background: "white",
          },
          {
            date: "7.8",
            content: ["전교인 야유회"],
            background: "grey",
          },
          {
            date: "7.9-16",
            content: ["원주민 선교"],
            background: "white",
          },
          {
            date: "7.23-27",
            content: ["한인세계선교대회"],
            background: "grey",
          },
          {
            date: "8.14-15",
            content: ["원주민 선교지 방문"],
            background: "white",
          },
          {
            date: "8.26-9.1",
            content: ["아이티 단기선교"],
            background: "grey",
          },
          {
            date: "10.12-13",
            content: ["2012 교회학교 교사 컨퍼런스"],
            background: "white",
          },
          {
            date: "11.18",
            content: ["찬양경연대회"],
            background: "grey",
          },
          {
            date: "11.23-25",
            content: ["부흥회: 이기성목사(밴쿠버 삼성교회)"],
            background: "white",
          },
          {
            date: "12.22",
            content: ["Saddle Lake 원주민 지역방문"],
            background: "grey",
          },
        ],
      },
      2013: {
        year: 2013,
        percent: 30,
        history: [
          {
            date: "1.11-12",
            content: [
              "청년부 새생명축제(E.P.I.C) 강사: Chris Chun(Golden Gate Seminary교수)",
            ],
            background: "white",
          },
          {
            date: "2.9",
            content: ["선교세미나(강사-정성헌선교사, SEED CA대표)"],
            background: "grey",
          },
          {
            date: "2.10",
            content: ["선교사 파송식(김 빅토르, 강 나타샤 선교사)"],
            background: "white",
          },
          {
            date: "2.16",
            content: ["Saddle lake 아웃리치 시작"],
            background: "grey",
          },
          {
            date: "6.1",
            content: ["선교바자회"],
            background: "white",
          },
          {
            date: "7.5-22",
            content: ["카자흐스탄, 우크라이나 단기선교"],
            background: "grey",
          },
          {
            date: "8.12-17",
            content: ["하이티 단기선교"],
            background: "white",
          },
          {
            date: "9.15",
            content: ["전교인 체육대회"],
            background: "grey",
          },
          {
            date: "10.26",
            content: ["교회 설립 25주년 기념 음악 축제"],
            background: "white",
          },
          {
            date: "11.24",
            content: ["석찬권 부목사 사임"],
            background: "grey",
          },
          {
            date: "12.15",
            content: ["레이첼 전도사 사임"],
            background: "white",
          },
          {
            date: "12.25-28",
            content: ["GKYM대회: Rochester(USA)"],
            background: "grey",
          },
          {
            date: "12.29",
            content: ["권사 은퇴: 이효진 이경예 정회월"],
            background: "white",
          },
        ],
      },
      2014: {
        year: 2014,
        percent: 40,
        history: [
          {
            date: "1.18",
            content: ["찬양세미나(강사: 조지환 교수)"],
            background: "grey",
          },
          {
            date: "1.25",
            content: ["제직세미나(강사: 김동규 목사)"],
            background: "white",
          },
          {
            date: "2.7-8",
            content: ["신년 교사세미나(강사: 신현호, 함진원 목사)"],
            background: "grey",
          },
          {
            date: "2.",
            content: ["장로은퇴: 이태기"],
            background: "white",
          },
          {
            date: "4.6",
            content: [
              "직원선거를 위한 공동의회 안수집사 피택: 김선규 이상호 이선욱 조경천 안수집사 인준: 김경식 이상훈 권사 피택: 김은주 김경옥 오능숙 이인숙 이재남 이현호",
            ],
            background: "grey",
          },
          {
            date: "6.15",
            content: ["전교인 성경퀴즈대회"],
            background: "white",
          },
          {
            date: "6.30",
            content: ["선교바자회"],
            background: "grey",
          },
          {
            date: "8.8-18",
            content: ["아이티 단기선교"],
            background: "white",
          },
          {
            date: "10.11",
            content: [
              "임직식 안수집사 임직: 김선규 이상호 이선욱 조경천 안수집사 취임: 김경식 이상훈 권사임직: 김은주 김경옥 오능숙 이인숙 이재남 이현호",
            ],
            background: "grey",
          },
          {
            date: "11.1",
            content: ["원솜니 목사 부임"],
            background: "white",
          },
          {
            date: "11.8",
            content: ["에드먼턴 남성합창단 창단연주회(본당)"],
            background: "grey",
          },
          {
            date: "11.29",
            content: ["에드먼턴 성탄연합집회(본당)"],
            background: "white",
          },
        ],
      },
      2015: {
        year: 2015,
        percent: 50,
        history: [
          {
            date: "1.23-25",
            content: ["신년부흥회, 강사: 이우제 목사"],
            background: "grey",
          },
          {
            date: "2.11",
            content: ["제직세미나"],
            background: "white",
          },
          {
            date: "3.31-4.4",
            content: ["고난주간 특별새벽기도회"],
            background: "grey",
          },
          {
            date: "4.25",
            content: ["선교바자회"],
            background: "white",
          },
          {
            date: "5.8-10",
            content: ["전교인 선교 첫걸음이야기 강사: 이규준 선교사(위클리프)"],
            background: "grey",
          },
          {
            date: "5.12-14",
            content: ["총회참석(총대: 정동호 목사, 이종원/김수련 장로)"],
            background: "white",
          },
          {
            date: "6.28",
            content: ["선교파송예배(카자흐스탄.원주민)"],
            background: "grey",
          },
          {
            date: "8.8-17",
            content: ["아이티 단기선교"],
            background: "white",
          },
          {
            date: "9.20",
            content: ["직원선거를 위한 공동의회 장로피택: 김경식 최경보"],
            background: "grey",
          },
          {
            date: "9.26",
            content: ["교사세미나"],
            background: "white",
          },
          {
            date: "9.27",
            content: ["교회창립 제 27주년 기념주일"],
            background: "grey",
          },
          {
            date: "9.27",
            content: ["장로은퇴: 정용환"],
            background: "white",
          },
          {
            date: "10.31",
            content: ["기획당회 및 안수집사. 권사회 수련회 장소: Camp Oselia"],
            background: "grey",
          },
          {
            date: "11.25",
            content: ["다락방별 성경퀴즈대회"],
            background: "white",
          },
          {
            date: "12.28-1.2",
            content: ["특별새벽기도회"],
            background: "grey",
          },
        ],
      },
      2016: {
        year: 2016,
        percent: 60,
        history: [
          {
            date: "1.30",
            content: ["제직세미나"],
            background: "white",
          },
          {
            date: "3.12",
            content: ["임직식 장로장립: 김경식 최경보"],
            background: "grey",
          },
          {
            date: "3.22-26",
            content: ["고난주간 특별새벽기도회"],
            background: "white",
          },
          {
            date: "3.25",
            content: ["에드먼턴 연합 성금요예배(본당)"],
            background: "grey",
          },
          {
            date: "4.24",
            content: ["임시공동의회, 의제: 교육관 건축위원회 인준건"],
            background: "white",
          },
          {
            date: "7.3-11",
            content: ["카작 선교"],
            background: "grey",
          },
          {
            date: "7.6-18",
            content: ["원주민 선교"],
            background: "white",
          },
          {
            date: "8.8-13",
            content: ["아이티 선교"],
            background: "grey",
          },
          {
            date: "9.10",
            content: ["중직자 부부세미나"],
            background: "white",
          },
          {
            date: "11.20",
            content: ["전교인 성경퀴즈대회"],
            background: "grey",
          },
          {
            date: "12.27-30",
            content: ["지킴(gkym) Rochester’16"],
            background: "white",
          },
        ],
      },
      2017: {
        year: 2017,
        percent: 70,
        history: [
          {
            date: "1.1",
            content: ["제직총회"],
            background: "grey",
          },
          {
            date: "1.8",
            content: [
              "직원선거를 위한 공동의회 안수집사 집사 인준: 문광식 김재원 권사 인준: 박한숙 김진화",
            ],
            background: "white",
          },
          {
            date: "1.20",
            content: [
              "2017 제직 및 소그룹 리더 세미나 (조찬주-커피브레이크 국제 강사)",
            ],
            background: "grey",
          },
          {
            date: "2.10-11",
            content: ["선교집회(길예평 선교사)"],
            background: "white",
          },
          {
            date: "2.12-4.8",
            content: ["교육관 건축을 위한 릴레이 금식기도"],
            background: "grey",
          },
          {
            date: "4.26",
            content: ["최윤석 선교사-일본선교보고"],
            background: "white",
          },
          {
            date: "7.2-10",
            content: ["카자흐스탄 선교"],
            background: "grey",
          },
          {
            date: "7.10-21",
            content: ["원주민 선교"],
            background: "white",
          },
          {
            date: "8.5-14",
            content: ["하이티 선교"],
            background: "grey",
          },
          {
            date: "9.10",
            content: ["교육관 기공예배 및 하기 단기선교 발표회"],
            background: "white",
          },
          {
            date: "10.8",
            content: [
              "임직식 안수집사 취임: 문광식 김재원 권사취임: 김진화 박한숙",
            ],
            background: "grey",
          },
          {
            date: "10.20-22",
            content: ["부흥회-이상민 목사(대구서문교회)"],
            background: "white",
          },
        ],
      },
      2018: {
        year: 2018,
        percent: 80,
        history: [
          {
            date: "1.7",
            content: ["은퇴: 이종원 장로, 남효정, 최형복 권사"],
            background: "grey",
          },
          {
            date: "1.20",
            content: ["제직 세미나: 송병현 교수(백석신학대학원)"],
            background: "white",
          },
          {
            date: "3.11,18",
            content: [
              "직원선거를 위한 공동의회 장로 피택: 노황률 안성운 집사 인준: 임점표 집사 피택: 박중완 정석재 박재범 남궁섭 권사 피택: 유은숙 최영옥 김숙경 이영희 송진옥",
            ],
            background: "grey",
          },
          {
            date: "4.13-15, 20-22",
            content: ["카이로스 코스"],
            background: "white",
          },
          {
            date: "4.29",
            content: ["청년부 단기선교를 위한 Fund raising"],
            background: "grey",
          },
          {
            date: "5.8-10",
            content: ["해외한인장로회 제 43회 총회: 정동호 목사, 박태규 장로"],
            background: "white",
          },
          {
            date: "5.27",
            content: ["전교인 성경퀴즈대회"],
            background: "grey",
          },
          {
            date: "6.24",
            content: ["창립 30주년 기념 체육대회"],
            background: "white",
          },
          {
            date: "9.29",
            content: [
              "교회창립 30주년 기념 임직식 장로 은퇴: 이종원 장로 장립: 노황률, 안성운 안수집사 취임: 임점표 안수집사 임직: 박중완 정석재 박재범 남궁섭 권사 임직: 유은숙 최영옥 김숙경 이영희 송진옥",
            ],
            background: "grey",
          },
          {
            date: "12.30",
            content: ["은희출 목사 사임 정요셉 목사 부임"],
            background: "white",
          },
        ],
      },
      2019: {
        year: 2019,
        percent: 90,
        history: [
          {
            date: "1.6",
            content: ["안수집사 은퇴: 남상렬"],
            background: "grey",
          },
          {
            date: "2.2",
            content: ["제직 세미나"],
            background: "white",
          },
          {
            date: "2.3",
            content: ["교육관 입당 예배"],
            background: "grey",
          },
          {
            date: "3.28-30",
            content: ["에드먼턴 Youth 연합수련회"],
            background: "white",
          },
          {
            date: "4.16-20",
            content: ["고난주간 특별 새벽기도회"],
            background: "grey",
          },
          {
            date: "6.2",
            content: ["성경퀴즈 대회"],
            background: "white",
          },
          {
            date: "7.1-12",
            content: ["원주민 선교"],
            background: "grey",
          },
          {
            date: "7.6-15",
            content: ["카자흐스탄 선교"],
            background: "white",
          },
          {
            date: "11.26-12.5",
            content: ["세네갈 선교"],
            background: "grey",
          },
          {
            date: "12.7",
            content: ["에드먼턴교회 성탄연합찬양제(소망교회)"],
            background: "white",
          },
          {
            date: "12.13-15",
            content: [
              "말씀사경회(강사: 장부완 목사, 미국 펜 스테이트 영광장로교회)",
            ],
            background: "grey",
          },
          {
            date: "12.24-30",
            content: ["세네갈 선교(EM)"],
            background: "white",
          },
        ],
      },
    },
  },
  2020: {
    initialYear: 2020,
    year: 2020,
    shortYear: 20,
    initialPercent: 0,
    timeLineArr: [2020, 2021, 2022, 2023, 2024],
    timeLine: {
      2020: {
        year: 2020,
        percent: 0,
        history: [
          {
            date: "1.5",
            content: ["공동의회(예결산을 위한)"],
            background: "grey",
          },
          {
            date: "1.5",
            content: ["윤병섭 목사 부임"],
            background: "white",
          },
          {
            date: "1.7-11",
            content: ["신년 특별 새벽기도회"],
            background: "grey",
          },
          {
            date: "1.7",
            content: ["바이블 동서남북 성경공부(강사: 김재유 선교사) 6주간"],
            background: "white",
          },
          {
            date: "2.26",
            content: ["여선교회 연합 헌신 예배"],
            background: "grey",
          },
          {
            date: "6.28",
            content: ["주일학교 수료예배"],
            background: "white",
          },
          {
            date: "12.27",
            content: ["권사은퇴: 김진화"],
            background: "grey",
          },
        ],
      },
      2021: {
        year: 2021,
        percent: 10,
        history: [
          {
            date: "1.3",
            content: ["공동의회(예결산을 위한)"],
            background: "white",
          },
          {
            date: "8.1",
            content: ["유지영 목사 부임"],
            background: "grey",
          },
          {
            date: "11.8-16",
            content: ["세네갈 선교"],
            background: "white",
          },
          {
            date: "12.26",
            content: ["최요한 목사 부임"],
            background: "grey",
          },
          {
            date: "12.26",
            content: ["장로은퇴: 박태규 김수련"],
            background: "white",
          },
        ],
      },
      2022: {
        year: 2022,
        percent: 20,
        history: [
          {
            date: "5.20-16",
            content: ["세네갈 목회자 세미나"],
            background: "grey",
          },
          {
            date: "6.11-7.2",
            content: ["직분자 학교"],
            background: "white",
          },
          {
            date: "7.5-8",
            content: ["원주민 선교"],
            background: "grey",
          },
          {
            date: "7.10",
            content: ["내규 수정을 위한 임시 공동의회"],
            background: "white",
          },
          {
            date: "10.21-30",
            content: ["카자흐스탄 목회자 세미나"],
            background: "grey",
          },
          {
            date: "11.1",
            content: ["정요셉 목사 사임"],
            background: "white",
          },
          {
            date: "11.7-17",
            content: ["세네갈 선교"],
            background: "grey",
          },
          {
            date: "11.27",
            content: [
              "직원선거를 위한 공동의회 안수집사 피택: 김진영 김병철 임진원 길호성 전영민 안수집사 인준: 김규만 권사 피택: 김금의 윤동미 박정림 홍 성 한새희 권사 인준: 박지숙",
            ],
            background: "white",
          },
        ],
      },
      2023: {
        year: 2023,
        percent: 30,
        history: [
          {
            date: "4.7",
            content: ["에드먼턴 교회연합 부활절 연합예배"],
            background: "grey",
          },
          {
            date: "4.8",
            content: [
              "임직식 안수집사 임직: 김진영 김병철 임진원 길호성 전영민 안수집사 취임: 김규만 권사 임직: 김금의 윤동미 박정림 홍 성 한새희 권사 취임: 박지숙",
            ],
            background: "white",
          },
          {
            date: "6.3",
            content: ["세네갈 목회자 세미나"],
            background: "grey",
          },
          {
            date: "7.3-11",
            content: ["원주민 선교"],
            background: "white",
          },
          {
            date: "8.6",
            content: ["이수지 전도사 부임"],
            background: "grey",
          },
          {
            date: "9.15-29",
            content: ["카자흐스탄 선교 및 목회자 세미나"],
            background: "white",
          },
          {
            date: "10.14-15",
            content: ["말씀사경회 강사: 류효근목사"],
            background: "grey",
          },
          {
            date: "11.7-16",
            content: ["세네갈 선교"],
            background: "white",
          },
        ],
      },
      2024: {
        year: 2024,
        percent: 40,
        history: [
          {
            date: "1.1",
            content: ["Grace Church 분리 독립"],
            background: "grey",
          },
          {
            date: "1.14",
            content: ["공동의회(예결산을 위한)"],
            background: "white",
          },
        ],
      },
    },
  },
};

export default HistoryData;
